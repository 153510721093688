const config = {
    API_ENDPOINT: "https://verify-api.prophetbots.com",
    TG_BOT_NAME: "ProphetVerifyBot",

    
    CONTRACT_ADDRESS: "0xA9fbcC25435AD713a9468D8c89dd7baAe8914e3a",
    WEB3_PROVIDER: "https://eth-mainnet.g.alchemy.com/v2/Q545k25IJFJQq85MMA43-8JYaP1FJwdl",
    WEB3_PROJECT_ID: "93599da170bedc0633ef006a7d19aa25"
}

export {
    config
}
