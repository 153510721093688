import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import './App.css'
import { useWeb3Modal } from '@web3modal/react'
import { Button } from '../../src/shared/Button/Button'
import HeroPolygon from './components/HeroPolygon'
/* import { signShaXV4 } from 'https://sharp-rqctx.prophetbots.io/verify-sig.js' */
import { useDisconnect } from 'wagmi'

import TelegramButton from '../shared/TelegramButton/TelegramButton'
import { LoginButton } from '@telegram-auth/react'
//import TelegramLoginButton from "react-telegram-login"
import Header from './components/PageHeader'
import Footer from './components/Footer'

import { useAccount, useSignMessage } from 'wagmi'
import FlashingStarsSVG from './components/svgs/FlashingStars'
import { AppContext } from '../AppProvider'

import { config } from '../config'

const ERROR_MESSAGES = {
  WALLET_ALREADY_CONNECTED: 'Wallet is already connected!',
  INVALID_AUTH: 'Invalid auth, try again!',
  INVALID_WALLET: 'Invalid wallet, use another wallet!',
  USER_NOT_FOUND: 'User not found!',
}

async function sendRequest(method, params) {
  const requestBody = JSON.stringify({
    jsonrpc: '2.0',
    id: 1,
    method: method,
    params: params,
  })

  const response = await fetch(config.WEB3_PROVIDER, {
    method: 'POST',
    body: requestBody,
    headers: {
      'Content-Type': 'application/json',
    },
  })

  const responseData = await response.json()
  if (responseData.error) {
    throw new Error(responseData.error.message)
  }

  return responseData.result
}

async function checkSubscription(address) {
  const data =
    '0x70a08231000000000000000000000000' + address.toLowerCase().substring(2)

  const result = await sendRequest('eth_call', [
    {
      to: config.CONTRACT_ADDRESS,
      data: data,
    },
    'latest',
  ])

  const balance = parseInt(result, 16)
  const ethValue = balance / Math.pow(10, 18) // Convert wei to ether

  return ethValue
}

function ensureUsernameInQueryString(queryString) {
  const params = new URLSearchParams(queryString)
  // Check if 'username' is not present and set it to an empty string if absent
  if (!params.has('username')) {
    params.set('username', '')
  }
  // Return the modified query string
  return params.toString()
}

const App = () => {
  const [prophetBalanceTooLow, setProphetBalanceTooLow] = useState(false)
  const [tgOauthCode, setTgOauthCode] = useState({ id: '', username: '' })
  const [resultMessage, setResultMessage] = useState('')
  const [serverFailed, setServerFailed] = useState(false)
  const [nonce, setNonce] = useState(null)
  const [token, setToken] = useState(localStorage.getItem('token'))
  const [success, setSuccess] = useState(false)
  const [sharpContext, setSharpContext] = useState('')
  const [balance, setBalance] = useState(0)
  const [tier, setTier] = useState('')
  const [connectedAddress, setConnectedAddress] = useState('')
  const [walletError, setWalletError] = useState({
    message: 'Empty',
    visible: false,
  })
  const { disconnect } = useDisconnect()
  const [usernotfound, setUserNotFound] = useState(false)
  const [authExpired, setAuthExpired] = useState(false)

  async function removeWallet() {
    disconnect()
    const payload = { wallet: connectedAddress.toLowerCase() }
    const disconnecturl = 'https://api.prophetbots.io/verification/removeWallet'
    const sig = /* await signShaXV4(disconnecturl, payload) */ 'production'
    console.log('my token', token)
    fetch('https://api.prophetbots.io/verification/removeWallet', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-sharp': sig,
        authorization: token,
      },
      body: JSON.stringify({
        wallet: connectedAddress.toLowerCase(),
      }),
    })
      .then((res) => {
        console.log('Response', res)
        return res.json()
      })
      .then((data) => {
        if (!data || !data.r) {
          setResultMessage('User not found!')
          return
        }
        setConnectedAddress(data.r.address || '')
        if (!balance) {
          setBalance(data.r.balance || 0)
        }

        if (data.r.tier == 'tierF') {
          setTier('No tier')
        } else if (data.r.tier == 'tier0') {
          setTier('🪸 Tier 0')
        } else if (data.r.tier == 'tier1') {
          setTier('🦐 Tier 1')
        } else if (data.r.tier == 'tier2') {
          setTier('🦞 Tier2')
        } else if (data.r.tier == 'tier3') {
          setTier('🐳 Tier3')
        } else if (data.r.tier == 'tier4') {
          setTier('🤖 AI Tier')
        } else {
          setTier('Unknown Tier')
        }
        console.log('tier after remove', data.r.tier)
      })
  }

  const { appLoaded, setAppLoaded } = useContext(AppContext)

  const { open } = useWeb3Modal()
  const { address } = useAccount()

  useEffect(() => {
    if (address && !nonce) {
      const payload = { wallet: address.toLowerCase() }
      const url = 'https://api.prophetbots.io/verification/generateNonce'
      const sig = /* await signShaXV4(disconnecturl, payload) */ 'production'
      const headers = {
        'Content-Type': 'application/json',
        authorization: token,
        'x-sharp': sig,
      }
      setConnectedAddress(address)
      fetch('https://api.prophetbots.io/verification/generateNonce', {
        method: 'POST',
        headers,
        body: JSON.stringify({
          wallet: address.toLowerCase(),
        }),
      })
        .then((response) => {
          return response.json()
        })
        .then((data) => {
          if (data.e === 'INVALID_AUTH') {
            setAuthExpired(true)
          }
          if (data.s === 1) {
          } else {
            console.error('Request failed with status:', data.s)
            return undefined
          }
          if (data.r) {
            setNonce(data.r.nonce)
          } else {
            console.error('Request failed with status:', 500)
          }
        })
    }
  }, [address])

  const handleWalletError = () => {
    setWalletError({
      message: 'You have to connect your Telegram first.',
      visible: true,
    })
  }

  useEffect(() => {
    if (!walletError.visible) return

    setTimeout(() => {
      setWalletError((oldError) => ({ ...oldError, visible: false }))
    }, 1000)
  }, [walletError])

  useEffect(() => {
    setTimeout(() => setAppLoaded(true), 0)
  }, [setAppLoaded])

  const { data, /*isError, isLoading, isSuccess,*/ signMessage } =
    useSignMessage({
      message: `ProphetBots - Verification
We need you to verify your wallet.
Before signing verify the website is:
https://verify.prophetbots.io

> ${address?.toLowerCase()}
> ${Object.keys(tgOauthCode).length ? tgOauthCode.id.toString() : 0}
> ${sharpContext}
> ${nonce}`,
    })

  const MAX_DEGREE = 0.5
  const MAX_OFFSET_X = 7
  const MAX_OFFSET_Y = 3

  const transformed = useRef(false)
  const refStars = useRef(null)

  const mouseMove = (ev) => {
    const transform = (x, y, degrees) => {
      if (!refStars.current) return
      refStars.current.style.transform = `translate(${x}px, ${y}px) rotate(${degrees}deg)`
    }

    const target = ev.currentTarget

    const center = { x: target.offsetWidth / 2, y: target.offsetHeight / 2 }
    const point = { x: ev.clientX, y: ev.clientY }
    const xPoint = point.x - center.x
    const yPoint = point.y - center.y
    const x = (xPoint / center.x) * MAX_OFFSET_X
    const y = (yPoint / center.y) * MAX_OFFSET_Y
    const angle = ((-xPoint + yPoint * 2) / (center.x + center.y)) * MAX_DEGREE

    transform(x, y, angle)
    transformed.current = true
  }

  useEffect(() => {
    document.documentElement.addEventListener('mousemove', mouseMove)

    return () => {
      document.documentElement.removeEventListener('mousemove', mouseMove)
    }
  }, [])

  const handleTelegramResponse = useCallback(async (response) => {
    setTgOauthCode(response)
    const hashlessDataNonString = Object.fromEntries(
      Object.entries(response).filter(([key]) => key !== 'hash')
    )

    var url = 'api.prophetbots.io/verification/authTelegram'
    let hashlessData = new URLSearchParams(hashlessDataNonString).toString()

    console.log(hashlessData)
    const { hash } = response

    if (!hashlessData || !hash) {
      console.log('Missing hashdata')
    }
    var payload = {
      telegramCallback: hashlessData,
      telegramHash: hash,
    }

    const sig = /* await signShaXV4(disconnecturl, payload) */ 'production'

    const headers = {
      'Content-Type': 'application/json',
      'x-sharp': sig,
      authorization: token,
    }

    console.log('FETCHING')

    fetch('https://api.prophetbots.io/verification/authTelegram', {
      headers,
      method: 'POST',
      body: JSON.stringify({
        telegramCallback: hashlessData,
        telegramHash: hash,
      }),
    })
      .then((res) => {
        console.log('Response', res)
        return res.json()
      })
      .then((data) => {
        console.log('normaldata', data)
        console.log(data.r, 'datar')
        if (data.e === 'INVALID_AUTH') {
          setAuthExpired(true)
          /* window.location.reload() */
          return
        }
        console.log('data error', data.error)
        if (data.error === 'USER_NOT_FOUND') {
          setUserNotFound(true)
          return
        }
        if (!data || !data.r) {
          setResultMessage('User not found!')
        }
        setSharpContext(data.r.authContext)
        console.log('my token', data.r.auth)
        setToken(data.r.auth)
        if (Object.keys(data.r.currentTier).length === 3) {
          if (data.r.currentTier.tier == 'tierF') {
            setTier('No tier')
          } else if (data.r.currentTier.tier == 'tier0') {
            setTier('🪸 Tier 0')
          } else if (data.r.currentTier.tier == 'tier1') {
            setTier('🦐 Tier 1')
          } else if (data.r.currentTier.tier == 'tier2') {
            setTier('🦞 Tier2')
          } else if (data.r.currentTier.tier == 'tier3') {
            setTier('🐳 Tier3')
          } else if (data.r.currentTier.tier == 'tier4') {
            setTier('🤖 AI Tier')
          } else {
            setTier('Unknown Tier')
          }
          if (!balance) {
            setBalance(data.r.currentTier.balance)
          }
          setConnectedAddress(data.r.currentTier.holdingWallet)
          setSuccess(true)
          return
        }
      })

    //const telegramIframe = document.getElementById(`telegram-login-${config.TG_BOT_NAME}`)
    //telegramIframe.src = telegramIframe.src
  }, [])

  useEffect(() => {
    if (!nonce && address && data)
      return setResultMessage('Something went wrong, try reloading the page.')

    if (!nonce) return

    if (address && Object.keys(tgOauthCode).length > 2 && data) {
      setProphetBalanceTooLow(false)
      const id = tgOauthCode?.id
      const username = tgOauthCode?.username

      if (!id) {
        setResultMessage('Failed to get Telegram details')
        setServerFailed(true)
        return
      }
      const payload = { wallet: address.toLowerCase() }
      const url = 'https://api.prophetbots.io/verification/preCheck'
      const sig = /* await signShaXV4(disconnecturl, payload) */ 'production'
      fetch('https://api.prophetbots.io/verification/preCheck', {
        method: 'POST',
        headers: {
          'x-sharp': sig,
          'Content-Type': 'application/json',
          authorization: token,
        },
        body: JSON.stringify({
          wallet: address.toLowerCase(),
        }),
      })
        .then((res) => res.json())
        .then(async (resData) => {
          if (!resData || !Object.keys(resData.r)) {
            setResultMessage(`Failed to check $Prophet balance`)
            setProphetBalanceTooLow(false)
            setServerFailed(true)

            return
          }
          if (resData.e === 'INVALID_AUTH') {
            setAuthExpired(true)
            setResultMessage('Failed to verify account, auth failure.')
            setProphetBalanceTooLow(false)
            setServerFailed(true)
            /* window.location.reload() */
            return
          }

          let params = {}

          if (username) {
            params = {
              address,
              telegram_id: id,
              username,
              signature: data,
              nonce,
            }
          } else {
            params = {
              address,
              telegram_id: id,
              username,
              signature: data,
              nonce,
            }
          }

          const payload = {
            walletNonce: nonce,
            walletAddress: address.toLowerCase(),
            walletSignature: data.toLowerCase(),
          } // ADDED THIS
          const url = 'https://api.prophetbots.io/verification/connectWallet' // ADDED THIS
          const sig =
            /* await signShaXV4(disconnecturl, payload) */ 'production'
          return fetch(
            'https://api.prophetbots.io/verification/connectWallet',
            {
              method: 'POST',
              headers: {
                'x-sharp': sig,
                authorization: token,
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                walletNonce: nonce,
                walletAddress: address.toLowerCase(),
                walletSignature: data.toLowerCase(),
              }),
            }
          )
        })
        .then((res) => {
          if (!res) return { message: 'handled' }
          return res.json()
        })
        .then((res) => {
          if (!res?.s && res?.message !== 'handled') {
            setResultMessage('Failed to verify account.')
            setProphetBalanceTooLow(false)
            setServerFailed(true)
            return
          }

          if (!res.r) {
            if (Object.keys(ERROR_MESSAGES).includes(res.e)) {
              setResultMessage(ERROR_MESSAGES[res.e])
              if (res.e === 'INVALID_AUTH') {
                setAuthExpired(true)
                /* window.location.reload() */
              }
            }
            setProphetBalanceTooLow(false)
            setServerFailed(true)
            return
          }
          if (!balance) {
            setBalance(res.r.balance)
          }

          setConnectedAddress(res.r.address)
          if (res?.message === 'handled') return
          setSuccess(true)
          setProphetBalanceTooLow(false)
          setResultMessage(
            `Successfully verified account! Your tier is ${res.r.tier}`
          )
        })
        .catch((err) => {
          setResultMessage('Failed to check, internal server error')
          setServerFailed(true)
        })

      /* checkSubscription(address.toString())
        .catch((error) => {
          return undefined
        })
        .then((res) => {
          if (!res && res !== 0) {
            setResultMessage(`Failed to check $Prophet balance`)

            setProphetBalanceTooLow(false)
            return
          }

          if (res < 1000) {
            setResultMessage(
              `You have less than 1000 $PROPHET. $PROPHET balance: ${res}`
            )
            setProphetBalanceTooLow(true)
            return
          }

          const params = {
            address,
            telegram_id: id,
            username,
            signature: data,
            nonce,
          }
          return fetch(
            `${config.API_ENDPOINT}/verify?data=${btoa(JSON.stringify(params))}`
          )
        })
        .then((res) => {
          if (!res) return { message: false }
          return res.json()
        })
        .then((res) => {
          if (!res?.message) {
            setResultMessage('Failed to verify account.')
            setProphetBalanceTooLow(false)

            return
          }

          setProphetBalanceTooLow(false)
          setResultMessage('Successfully verified account!')
        }) */
    }
    console.log(address)
    console.log(data)
    if (address && !data) {
      console.log('Prompt signature')
      signMessage()
    }
  }, [address, tgOauthCode, data, nonce, signMessage])

  const handleLogout = () => {
    window.location.reload()
  }

  return (
    <>
      <Header />
      <div
        id='fullpage'
        className='fullpage-wrapper'
        style={{
          height: '100%',
          position: 'relative',
          touchAction: 'none',
          transform: 'translate3d(0px, 0px, 0px)',
        }}
      >
        <section
          id='roadmap'
          className='section Hero_hero__V4VDl fp-section active fp-table fp-is-overflow fp-completely animated'
          data-screen={0}
          style={{}}
        >
          <div className='fp-overflow' tabIndex={-1}>
            <div className='Hero_wrapper__YmYlg'>
              {tgOauthCode?.username || tgOauthCode?.id ? (
                <h1 className='dashboard'>Dashboard</h1>
              ) : (
                <div className='hero'>
                  <div className='connecttext'>
                    Enter the world of profit, instantly.
                  </div>
                  <div className='connecttext-2'>
                    Make sure you have an account before verifying on the
                    verification portal. You can create one by using the
                    Telegram bot. <br />
                    <span>
                      <a
                        rel='noreferrer'
                        href='https://t.me/officialprophetbot'
                        target='_blank'
                      >
                        Telegram bot
                      </a>
                    </span>
                  </div>
                  <div className='connecttext'>
                    Begin by connecting your telegram.
                  </div>
                  <div className='signinbutton'>
                    {/*  <TelegramButton onResponse={handleTelegramResponse} /> */}
                    <LoginButton
                      botUsername={config.TG_BOT_NAME}
                      onAuthCallback={async (data) => {
                        await handleTelegramResponse(data)
                      }}
                    />
                  </div>
                </div>
              )}
              {authExpired && !tgOauthCode ? (
                <p>Your session has expired, log in again.</p>
              ) : (
                <></>
              )}
              {tgOauthCode?.username || tgOauthCode?.id ? (
                <div className='Hero_cards__p7RcH'>
                  <div className={`${appLoaded ? 'revealcards' : ''}`}>
                    <div className='dashboardmaincard'>
                      <div className='yourprophet'>
                        Your % of claim:{' '}
                        <p className='comingsoon'>Coming Soon</p>
                      </div>
                      <div className='claimsubtext'>
                        YOU MUST OWN 2000 $PROPHET TO BE ENTITLED TO YOUR
                        REVENUE SHARE DISTRIBUTION.
                      </div>
                    </div>
                    <div className='dashboardsubcards'>
                      <div className='profilecard'>
                        <span className='tgusername'>
                          <img
                            src='/telegram.svg'
                            className='telegram_logo'
                            alt=''
                          />
                          @{tgOauthCode?.username || 'No username'}
                        </span>
                        <span>
                          Tier: <span className=''>{tier || 'No tier'}</span>
                        </span>
                        <span className='walletprofile'>
                          Wallet:{' '}
                          {connectedAddress
                            ? `${connectedAddress.slice(
                                0,
                                5
                              )}...${connectedAddress.slice(-4)}`
                            : 'Not connected'}
                        </span>
                      </div>
                      <div className='profilecard'>
                        <p>Your Prophet balance:</p>
                        <div className='verify-balance-logo-text'>
                          <img
                            className='verify-balance-icon'
                            src='/images/icons/prophet-balance.png'
                          />
                          <h5 className='verify-balance'>
                            {balance ? balance.toFixed(2) : '0.00'}
                          </h5>
                        </div>
                      </div>
                    </div>

                    <div className='profilebuttons'>
                      <div className='mainbuttons'>
                        {connectedAddress ? (
                          <a
                            href={`https://etherscan.io/address/${connectedAddress}`}
                            target='_blank'
                          >
                            <button className='buy-prophet'>
                              View Address on Etherscan
                              <img
                                src='/etherscan-logo.svg'
                                className='etherscanlogo'
                                alt='etherscan'
                              />
                            </button>
                          </a>
                        ) : (
                          <></>
                        )}
                        <button
                          className='connect-wallet'
                          icon='/images/icons/home.svg'
                          onClick={handleLogout}
                        >
                          Logout
                          <svg
                            fill='#ffffff'
                            height='12px'
                            width='32px'
                            version='1.1'
                            id='Capa_1'
                            xmlns='http://www.w3.org/2000/svg'
                            xmlnsXlink='http://www.w3.org/1999/xlink'
                            viewBox='0 0 384.971 384.971'
                            xmlSpace='preserve'
                            stroke='#ffffff'
                          >
                            <g id='SVGRepo_bgCarrier' stroke-width='0'></g>
                            <g
                              id='SVGRepo_tracerCarrier'
                              stroke-linecap='round'
                              stroke-linejoin='round'
                            ></g>
                            <g id='SVGRepo_iconCarrier'>
                              {' '}
                              <g>
                                {' '}
                                <g id='Sign_Out'>
                                  {' '}
                                  <path d='M180.455,360.91H24.061V24.061h156.394c6.641,0,12.03-5.39,12.03-12.03s-5.39-12.03-12.03-12.03H12.03 C5.39,0.001,0,5.39,0,12.031V372.94c0,6.641,5.39,12.03,12.03,12.03h168.424c6.641,0,12.03-5.39,12.03-12.03 C192.485,366.299,187.095,360.91,180.455,360.91z'></path>{' '}
                                  <path d='M381.481,184.088l-83.009-84.2c-4.704-4.752-12.319-4.74-17.011,0c-4.704,4.74-4.704,12.439,0,17.179l62.558,63.46H96.279 c-6.641,0-12.03,5.438-12.03,12.151c0,6.713,5.39,12.151,12.03,12.151h247.74l-62.558,63.46c-4.704,4.752-4.704,12.439,0,17.179 c4.704,4.752,12.319,4.752,17.011,0l82.997-84.2C386.113,196.588,386.161,188.756,381.481,184.088z'></path>{' '}
                                </g>{' '}
                                <g> </g> <g> </g> <g> </g> <g> </g> <g> </g>{' '}
                                <g> </g>{' '}
                              </g>{' '}
                            </g>
                          </svg>
                        </button>
                      </div>
                      <div className='walletbuttons'>
                        <a
                          href='https://www.dextools.io/app/en/ether/pair-explorer/0xa07674a55c6278244cc2113fbc9d1769b6dfca48'
                          target='_blank'
                        >
                          <button className='buy-prophet'>BUY $PROPHET</button>
                        </a>
                        {connectedAddress ? (
                          <button
                            className='connect-wallet'
                            icon='/images/icons/home.svg'
                            onClick={removeWallet}
                          >
                            Disconnect{' '}
                            {`${connectedAddress.slice(
                              0,
                              5
                            )}...${connectedAddress.slice(-4)}`}
                          </button>
                        ) : (
                          <button
                            className='connect-wallet'
                            onClick={
                              !!tgOauthCode?.id ? open : handleWalletError
                            }
                            icon='/images/icons/wallet.svg'
                          >
                            Connect Wallet
                            <img
                              className='walletConnect'
                              src='/WalletConnect.svg'
                              alt=''
                            />
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}

              <span
                className={`walletError ${
                  walletError.visible ? 'visible' : ''
                }`}
              >
                {walletError.message}
              </span>
              {connectedAddress && tgOauthCode.id ? (
                <div className='dashbuttons'>
                  <a href='https://t.me/officialprophetbot'>
                    <button className='returntotelegram'>
                      Return To Telegram
                    </button>
                  </a>
                </div>
              ) : (
                <></>
              )}
            </div>
            <div className='container' style={{ opacity: 1 }}>
              <div className='main-div-verify' />
              <div ref={refStars} className='Hero_starsWrap__IFqdS'>
                <div className='Hero_stars__YQuNc'>
                  <div
                    className={`FlashingStars_flashingStars__dywxh ${
                      appLoaded ? 'reveal' : ''
                    }`}
                    id='flashing-dots'
                  >
                    <FlashingStarsSVG />
                  </div>
                </div>
              </div>
              <div className='Hero_grid__+gadL'>
                <HeroPolygon />
              </div>
            </div>
            <Footer />
          </div>
        </section>
      </div>
    </>
  )
}

export default App
