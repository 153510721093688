import { useContext } from "react"
import { AppContext } from "../../AppProvider"



const Footer = () => {
    const { appLoaded, setAppLoaded } = useContext(AppContext) 

    return <footer className="Footer_footer__A08Qe">
        <div className="Footer_content__NE9u2">
            <div className={`Footer_footerLogo__uFPFC ${appLoaded ? "reveal" : ""}`}>
            <div className="Logo_logo__Lu8Pk">
                <img src="/images/logo-footer.svg" alt="" />
            </div>
            </div>
            <div className="Footer_footerCols__1h9lp">
            <div className={`Footer_col__66deo ${appLoaded ? "reveal" : ""}`}>
                <div className="Footer_head__WlCjX">Contact</div>
                <div className="Footer_body__TCvtU">
                <p>contact@prophetbots.com</p>
                </div>
            </div>
            <div className={`Footer_col__66deo ${appLoaded ? "reveal" : ""}`}>
                <div className="Footer_head__WlCjX">Links</div>
                <div className="Footer_body__TCvtU">
                <a href="">Buy $PROPHET</a>
                <a href="https://www.dextools.io/app/en/ether/pair-explorer/0xa07674a55c6278244cc2113fbc9d1769b6dfca48">
                    Dextools
                </a>
                </div>
            </div>
            <div className={`Footer_col__66deo ${appLoaded ? "reveal" : ""}`}>
                <div className="Footer_head__WlCjX">Socials</div>
                <div className="Footer_body__TCvtU">
                <a href="https://twitter.com/ProphetBots">Twitter</a>
                <a href="https://t.me/+n2tiEUcl4d1hMGI0" >
                    Telegram Group
                </a>
                <a href="https://t.me/+TbWSvYGzqaIyOTM0">Telegram Bot</a>
                </div>
            </div>
            <div className={`Footer_col__66deo ${appLoaded ? "reveal" : ""}`}>
                <div className="Footer_head__WlCjX">Resources</div>
                <div className="Footer_body__TCvtU">
                <a href="https://prophetbots.gitbook.io/prophetbots/">
                    Gitbook Documentation
                </a>
                <a href="/revenue-share">Revenue Share</a>
                </div>
            </div>
            </div>
        </div>
    </footer>
}

export default Footer