import { createContext, useEffect, useState } from 'react'
import { config } from './config'

const AppContext = createContext(null)

const AppProvider = ({ children }) => {
  const [appLoaded, setAppLoaded] = useState(false)
  const [nonce, setNonce] = useState(null)

  useEffect(() => {
    const clearData = () => {
      const cookies = document.cookie.split(';')

      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i]
        const eqPos = cookie.indexOf('=')
        const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie
        document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT'
      }

      localStorage.clear()
    }

    window.addEventListener('beforeunload', clearData)

    return () => {
      window.removeEventListener('beforeunload', clearData)
    }
  }, [])

  /*useEffect( () => {
            const sandboxInt = setInterval( () => {
                const telegramIframe = document.getElementById(`telegram-login-${config.TG_BOT_NAME}`)
                if( telegramIframe ) {
                    telegramIframe.setAttribute("sandbox", "allow-scripts allow-popups allow-forms allow-same-origin")
                    telegramIframe.src = telegramIframe.src
                    clearInterval(sandboxInt)
                }
            }, 250 )
      }, [] )*/

  return (
    <AppContext.Provider
      value={{
        appLoaded,
        setAppLoaded,
        nonce,
      }}
    >
      {children}
    </AppContext.Provider>
  )
}

export { AppProvider, AppContext }
