import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import App from './components/App'
import './index.css'
import { BrowserRouter } from 'react-router-dom'
import { AppProvider } from './AppProvider'

import { WagmiConfig, configureChains, createConfig, useConnect } from 'wagmi'
import { mainnet, goerli } from 'wagmi/chains'
import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum'
import { Web3Modal } from '@web3modal/react'

import { config } from './config'


const chains = [mainnet, goerli]
const { publicClient } = configureChains(chains, [w3mProvider({ projectId: config.WEB3_PROJECT_ID })])
const wagmiConfig = createConfig({
    autoConnect: true,
    connectors: w3mConnectors({ projectId: config.WEB3_PROJECT_ID, chains }),
    publicClient
})


const ethereumClient = new EthereumClient(wagmiConfig, chains)


ReactDOM.createRoot(document.getElementById('root')).render(
<>
    <WagmiConfig config={wagmiConfig}>
        <BrowserRouter>
            <AppProvider>
            <App />
            </AppProvider>
        </BrowserRouter>
    </WagmiConfig>

    <Web3Modal
        cacheProvider={true}
        projectId={config.WEB3_PROJECT_ID}
        ethereumClient={ethereumClient}
        themeMode="dark"
        themeVariables={{
            '--w3m-background-color': '#996BFF',
            '--w3m-overlay-backdrop-filter': 'blur(5px)',
            '--w3m-overlay-background-color': 'rgba(101,31,255,0.1)',
            '--w3m-font-family': 'Questrial, sans-serif',
        }}
    />
</>
)