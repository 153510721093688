import { useEffect } from 'react'
import { getRandomIntInclusive } from '../../utils/getRandomIntInclusive'

const HeroPolygon = () => {

    useEffect(() => {
        const wires = Array.from(
            document.querySelectorAll(".wire")
        )
        if (wires) {
          wires.forEach((wire) => {
            const delay = getRandomIntInclusive(0, 3)
    
            wire.style.animationDelay = `${delay}s`
    
            setInterval(() => {
                if (!wire.classList.contains("anim")) {
                    wire.classList.add("anim")
        
                    if (wire) {
                        wire.addEventListener("animationend", () => {
                            wire.classList.remove("anim")
            
                            const delay = getRandomIntInclusive(0, 3)
            
                            wire.style.animationDelay = `${delay}s`
                        })
                    }
                }
            }, 2000)
          })
        }
    }, [])

    return <div className="Hero_polygon__c3xND">
        <div className="Hero_topGradient__-s0Oy" />
        <div className="Hero_gradientBig__IswKm" />
        <svg width={1024} height={923} viewBox="0 0 1024 923" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_336_819)">
                <g clipPath="url(#clip1_336_819)">
                    <g clipPath="url(#clip2_336_819)">
                        <rect
                            x={1}
                            y={1}
                            width={168}
                            height={188}
                            stroke="#6E6EFF"
                            strokeWidth={2}
                        />
                        <rect
                            x={171}
                            y={1}
                            width={168}
                            height={188}
                            stroke="#6E6EFF"
                            strokeWidth={2}
                        />
                        <rect
                            x={341}
                            y={1}
                            width={168}
                            height={188}
                            stroke="#6E6EFF"
                            strokeWidth={2}
                        />
                        <rect
                            x={511}
                            y={1}
                            width={168}
                            height={188}
                            stroke="#6E6EFF"
                            strokeWidth={2}
                        />
                        <rect
                            x={681}
                            y={1}
                            width={168}
                            height={188}
                            stroke="#6E6EFF"
                            strokeWidth={2}
                        />
                        <rect
                            x={851}
                            y={1}
                            width={168}
                            height={188}
                            stroke="#6E6EFF"
                            strokeWidth={2}
                        />
                        <rect
                            x={1021}
                            y={1}
                            width={168}
                            height={188}
                            stroke="#6E6EFF"
                            strokeWidth={2}
                        />
                        <rect
                            x={1191}
                            y={1}
                            width={168}
                            height={188}
                            stroke="#6E6EFF"
                            strokeWidth={2}
                        />
                        <rect
                            x={1361}
                            y={1}
                            width={168}
                            height={188}
                            stroke="#6E6EFF"
                            strokeWidth={2}
                        />
                        <rect
                            x={1531}
                            y={1}
                            width={168}
                            height={188}
                            stroke="#6E6EFF"
                            strokeWidth={2}
                        />
                        <rect
                            x={1701}
                            y={1}
                            width={168}
                            height={188}
                            stroke="#6E6EFF"
                            strokeWidth={2}
                        />
                        <rect
                            x={1871}
                            y={1}
                            width={168}
                            height={188}
                            stroke="#6E6EFF"
                            strokeWidth={2}
                        />
                        <rect
                            x={2041}
                            y={1}
                            width={168}
                            height={188}
                            stroke="#6E6EFF"
                            strokeWidth={2}
                        />
                    </g>
                    <g clipPath="url(#clip3_336_819)">
                        <rect
                            x={1}
                            y={191}
                            width={168}
                            height={188}
                            stroke="#6E6EFF"
                            strokeWidth={2}
                        />
                        <rect
                            x={171}
                            y={191}
                            width={168}
                            height={188}
                            stroke="#6E6EFF"
                            strokeWidth={2}
                        />
                        <rect
                        x={341}
                        y={191}
                        width={168}
                        height={188}
                        stroke="#6E6EFF"
                        strokeWidth={2}
                        />
                        <rect
                        x={511}
                        y={191}
                        width={168}
                        height={188}
                        stroke="#6E6EFF"
                        strokeWidth={2}
                        />
                        <rect
                        x={681}
                        y={191}
                        width={168}
                        height={188}
                        stroke="#6E6EFF"
                        strokeWidth={2}
                        />
                        <rect
                        x={851}
                        y={191}
                        width={168}
                        height={188}
                        stroke="#6E6EFF"
                        strokeWidth={2}
                        />
                        <rect
                        x={1021}
                        y={191}
                        width={168}
                        height={188}
                        stroke="#6E6EFF"
                        strokeWidth={2}
                        />
                        <rect
                        x={1191}
                        y={191}
                        width={168}
                        height={188}
                        stroke="#6E6EFF"
                        strokeWidth={2}
                        />
                        <rect
                        x={1361}
                        y={191}
                        width={168}
                        height={188}
                        stroke="#6E6EFF"
                        strokeWidth={2}
                        />
                        <rect
                        x={1531}
                        y={191}
                        width={168}
                        height={188}
                        stroke="#6E6EFF"
                        strokeWidth={2}
                        />
                        <rect
                        x={1701}
                        y={191}
                        width={168}
                        height={188}
                        stroke="#6E6EFF"
                        strokeWidth={2}
                        />
                        <rect
                        x={1871}
                        y={191}
                        width={168}
                        height={188}
                        stroke="#6E6EFF"
                        strokeWidth={2}
                        />
                        <rect
                        x={2041}
                        y={191}
                        width={168}
                        height={188}
                        stroke="#6E6EFF"
                        strokeWidth={2}
                        />
                    </g>
                    <g clipPath="url(#clip4_336_819)">
                        <rect
                        x={1}
                        y={381}
                        width={168}
                        height={188}
                        stroke="#6E6EFF"
                        strokeWidth={2}
                        />
                        <rect
                        x={171}
                        y={381}
                        width={168}
                        height={188}
                        stroke="#6E6EFF"
                        strokeWidth={2}
                        />
                        <rect
                        x={341}
                        y={381}
                        width={168}
                        height={188}
                        stroke="#6E6EFF"
                        strokeWidth={2}
                        />
                        <rect
                        x={511}
                        y={381}
                        width={168}
                        height={188}
                        stroke="#6E6EFF"
                        strokeWidth={2}
                        />
                        <rect
                        x={681}
                        y={381}
                        width={168}
                        height={188}
                        stroke="#6E6EFF"
                        strokeWidth={2}
                        />
                        <rect
                        x={851}
                        y={381}
                        width={168}
                        height={188}
                        stroke="#6E6EFF"
                        strokeWidth={2}
                        />
                        <rect
                        x={1021}
                        y={381}
                        width={168}
                        height={188}
                        stroke="#6E6EFF"
                        strokeWidth={2}
                        />
                        <rect
                        x={1191}
                        y={381}
                        width={168}
                        height={188}
                        stroke="#6E6EFF"
                        strokeWidth={2}
                        />
                        <rect
                        x={1361}
                        y={381}
                        width={168}
                        height={188}
                        stroke="#6E6EFF"
                        strokeWidth={2}
                        />
                        <rect
                        x={1531}
                        y={381}
                        width={168}
                        height={188}
                        stroke="#6E6EFF"
                        strokeWidth={2}
                        />
                        <rect
                        x={1701}
                        y={381}
                        width={168}
                        height={188}
                        stroke="#6E6EFF"
                        strokeWidth={2}
                        />
                        <rect
                        x={1871}
                        y={381}
                        width={168}
                        height={188}
                        stroke="#6E6EFF"
                        strokeWidth={2}
                        />
                        <rect
                        x={2041}
                        y={381}
                        width={168}
                        height={188}
                        stroke="#6E6EFF"
                        strokeWidth={2}
                        />
                    </g>
                    <g clipPath="url(#clip5_336_819)">
                        <rect
                        x={1}
                        y={571}
                        width={168}
                        height={188}
                        stroke="#6E6EFF"
                        strokeWidth={2}
                        />
                        <rect
                        x={171}
                        y={571}
                        width={168}
                        height={188}
                        stroke="#6E6EFF"
                        strokeWidth={2}
                        />
                        <rect
                        x={341}
                        y={571}
                        width={168}
                        height={188}
                        stroke="#6E6EFF"
                        strokeWidth={2}
                        />
                        <rect
                        x={511}
                        y={571}
                        width={168}
                        height={188}
                        stroke="#6E6EFF"
                        strokeWidth={2}
                        />
                        <rect
                        x={681}
                        y={571}
                        width={168}
                        height={188}
                        stroke="#6E6EFF"
                        strokeWidth={2}
                        />
                        <rect
                        x={851}
                        y={571}
                        width={168}
                        height={188}
                        stroke="#6E6EFF"
                        strokeWidth={2}
                        />
                        <rect
                        x={1021}
                        y={571}
                        width={168}
                        height={188}
                        stroke="#6E6EFF"
                        strokeWidth={2}
                        />
                        <rect
                        x={1191}
                        y={571}
                        width={168}
                        height={188}
                        stroke="#6E6EFF"
                        strokeWidth={2}
                        />
                        <rect
                        x={1361}
                        y={571}
                        width={168}
                        height={188}
                        stroke="#6E6EFF"
                        strokeWidth={2}
                        />
                        <rect
                        x={1531}
                        y={571}
                        width={168}
                        height={188}
                        stroke="#6E6EFF"
                        strokeWidth={2}
                        />
                        <rect
                        x={1701}
                        y={571}
                        width={168}
                        height={188}
                        stroke="#6E6EFF"
                        strokeWidth={2}
                        />
                        <rect
                        x={1871}
                        y={571}
                        width={168}
                        height={188}
                        stroke="#6E6EFF"
                        strokeWidth={2}
                        />
                        <rect
                        x={2041}
                        y={571}
                        width={168}
                        height={188}
                        stroke="#6E6EFF"
                        strokeWidth={2}
                        />
                    </g>
                    <g clipPath="url(#clip6_336_819)">
                        <rect
                        x={1}
                        y={761}
                        width={168}
                        height={188}
                        stroke="#6E6EFF"
                        strokeWidth={2}
                        />
                        <rect
                        x={171}
                        y={761}
                        width={168}
                        height={188}
                        stroke="#6E6EFF"
                        strokeWidth={2}
                        />
                        <rect
                        x={341}
                        y={761}
                        width={168}
                        height={188}
                        stroke="#6E6EFF"
                        strokeWidth={2}
                        />
                        <rect
                        x={511}
                        y={761}
                        width={168}
                        height={188}
                        stroke="#6E6EFF"
                        strokeWidth={2}
                        />
                        <rect
                        x={681}
                        y={761}
                        width={168}
                        height={188}
                        stroke="#6E6EFF"
                        strokeWidth={2}
                        />
                        <rect
                        x={851}
                        y={761}
                        width={168}
                        height={188}
                        stroke="#6E6EFF"
                        strokeWidth={2}
                        />
                        <rect
                        x={1021}
                        y={761}
                        width={168}
                        height={188}
                        stroke="#6E6EFF"
                        strokeWidth={2}
                        />
                        <rect
                        x={1191}
                        y={761}
                        width={168}
                        height={188}
                        stroke="#6E6EFF"
                        strokeWidth={2}
                        />
                        <rect
                        x={1361}
                        y={761}
                        width={168}
                        height={188}
                        stroke="#6E6EFF"
                        strokeWidth={2}
                        />
                        <rect
                        x={1531}
                        y={761}
                        width={168}
                        height={188}
                        stroke="#6E6EFF"
                        strokeWidth={2}
                        />
                        <rect
                        x={1701}
                        y={761}
                        width={168}
                        height={188}
                        stroke="#6E6EFF"
                        strokeWidth={2}
                        />
                        <rect
                        x={1871}
                        y={761}
                        width={168}
                        height={188}
                        stroke="#6E6EFF"
                        strokeWidth={2}
                        />
                        <rect
                        x={2041}
                        y={761}
                        width={168}
                        height={188}
                        stroke="#6E6EFF"
                        strokeWidth={2}
                        />
                    </g>
                    <g clipPath="url(#clip7_336_819)">
                        <rect
                        x={1}
                        y={951}
                        width={168}
                        height={188}
                        stroke="#6E6EFF"
                        strokeWidth={2}
                        />
                        <rect
                        x={171}
                        y={951}
                        width={168}
                        height={188}
                        stroke="#6E6EFF"
                        strokeWidth={2}
                        />
                        <rect
                        x={341}
                        y={951}
                        width={168}
                        height={188}
                        stroke="#6E6EFF"
                        strokeWidth={2}
                        />
                        <rect
                        x={511}
                        y={951}
                        width={168}
                        height={188}
                        stroke="#6E6EFF"
                        strokeWidth={2}
                        />
                        <rect
                        x={681}
                        y={951}
                        width={168}
                        height={188}
                        stroke="#6E6EFF"
                        strokeWidth={2}
                        />
                        <rect
                        x={851}
                        y={951}
                        width={168}
                        height={188}
                        stroke="#6E6EFF"
                        strokeWidth={2}
                        />
                        <rect
                        x={1021}
                        y={951}
                        width={168}
                        height={188}
                        stroke="#6E6EFF"
                        strokeWidth={2}
                        />
                        <rect
                        x={1191}
                        y={951}
                        width={168}
                        height={188}
                        stroke="#6E6EFF"
                        strokeWidth={2}
                        />
                        <rect
                        x={1361}
                        y={951}
                        width={168}
                        height={188}
                        stroke="#6E6EFF"
                        strokeWidth={2}
                        />
                        <rect
                        x={1531}
                        y={951}
                        width={168}
                        height={188}
                        stroke="#6E6EFF"
                        strokeWidth={2}
                        />
                        <rect
                        x={1701}
                        y={951}
                        width={168}
                        height={188}
                        stroke="#6E6EFF"
                        strokeWidth={2}
                        />
                        <rect
                        x={1871}
                        y={951}
                        width={168}
                        height={188}
                        stroke="#6E6EFF"
                        strokeWidth={2}
                        />
                        <rect
                        x={2041}
                        y={951}
                        width={168}
                        height={188}
                        stroke="#6E6EFF"
                        strokeWidth={2}
                        />
                    </g>
                </g>
                <path
                d="M219 572H338.5V380H456.5"
                stroke="url(#paint0_linear_336_819)"
                strokeWidth={4}
                className="wire"
                style={{ animationDelay: "1.66512s" }}
                />
                <path
                d="M35 950H170V760.5H271"
                stroke="url(#paint1_linear_336_819)"
                strokeWidth={4}
                className="wire"
                style={{ animationDelay: "1.58296s" }}
                />
                <path
                d="M400.5 951.5H511V761.5H606"
                stroke="url(#paint2_linear_336_819)"
                strokeWidth={4}
                className="wire"
                style={{ animationDelay: "1.6285s" }}
                />
                <path
                d="M35 383H169V190.5H261"
                stroke="url(#paint3_linear_336_819)"
                strokeWidth={4}
                className="wire"
                style={{ animationDelay: "0.111153s" }}
                />
                <path
                d="M745 761H849.5V570H955"
                stroke="url(#paint4_linear_336_819)"
                strokeWidth={4}
                className="wire"
                style={{ animationDelay: "1.70477s" }}
                />
                <path
                d="M575 1141H679.5V951H785"
                stroke="url(#paint5_linear_336_819)"
                strokeWidth={4}
                className="wire"
                style={{ animationDelay: "0.129237s" }}
                />
                <path
                d="M745 1140H849.5V950H955"
                stroke="url(#paint6_linear_336_819)"
                strokeWidth={4}
                className="wire"
                style={{ animationDelay: "1.01293s" }}
                />
                <path
                d="M915 1140H1019.5V950H1125"
                stroke="url(#paint7_linear_336_819)"
                strokeWidth={4}
                className="wire"
                style={{ animationDelay: "0.696172s" }}
                />
                <path
                d="M1085 1140H1189.5V950H1295"
                stroke="url(#paint8_linear_336_819)"
                strokeWidth={4}
                className="wire"
                style={{ animationDelay: "2.98205s" }}
                />
                <path
                d="M1255 1140H1359.5V950H1465"
                stroke="url(#paint9_linear_336_819)"
                strokeWidth={4}
                className="wire"
                style={{ animationDelay: "2.13905s" }}
                />
                <path
                d="M1425 1140H1529.5V950H1635"
                stroke="url(#paint10_linear_336_819)"
                strokeWidth={4}
                className="wire"
                style={{ animationDelay: "2.44289s" }}
                />
                <path
                d="M1595 1140H1699.5V950H1805"
                stroke="url(#paint11_linear_336_819)"
                strokeWidth={4}
                className="wire"
                style={{ animationDelay: "1.32041s" }}
                />
                <path
                d="M1765 1140H1869.5V950H1975"
                stroke="url(#paint12_linear_336_819)"
                strokeWidth={4}
                className="wire"
                style={{ animationDelay: "2.93738s" }}
                />
                <path
                d="M1085 951H1189.5V761H1295"
                stroke="url(#paint13_linear_336_819)"
                strokeWidth={4}
                className="wire"
                style={{ animationDelay: "0.106878s" }}
                />
                <path
                d="M1935 1140H2039.5V950H2145"
                stroke="url(#paint14_linear_336_819)"
                strokeWidth={4}
                className="wire"
                style={{ animationDelay: "2.84016s" }}
                />
                <path
                d="M1255 950H1359.5V760H1465"
                stroke="url(#paint15_linear_336_819)"
                strokeWidth={4}
                className="wire"
                style={{ animationDelay: "1.22527s" }}
                />
                <path
                d="M1425 950H1529.5V760H1635"
                stroke="url(#paint16_linear_336_819)"
                strokeWidth={4}
                className="wire"
                style={{ animationDelay: "2.98913s" }}
                />
                <path
                d="M1765 950H1869.5V760H1975"
                stroke="url(#paint17_linear_336_819)"
                strokeWidth={4}
                className="wire"
                style={{ animationDelay: "2.15257s" }}
                />
                <path
                d="M1595 950H1699.5V760H1805"
                stroke="url(#paint18_linear_336_819)"
                strokeWidth={4}
                className="wire"
                style={{ animationDelay: "2.60988s" }}
                />
                <path
                d="M1255 761H1359.5V571H1465"
                stroke="url(#paint19_linear_336_819)"
                strokeWidth={4}
                className="wire"
                style={{ animationDelay: "1.21103s" }}
                />
                <path
                d="M1425 761H1529.5V571H1635"
                stroke="url(#paint20_linear_336_819)"
                strokeWidth={4}
                className="wire"
                style={{ animationDelay: "0.497894s" }}
                />
                <path
                d="M1595 760H1699.5V570H1805"
                stroke="url(#paint21_linear_336_819)"
                strokeWidth={4}
                className="wire"
                style={{ animationDelay: "2.68099s" }}
                />
                <path
                d="M1765 760H1869.5V570H1975"
                stroke="url(#paint22_linear_336_819)"
                strokeWidth={4}
                className="wire"
                style={{ animationDelay: "1.89809s" }}
                />
                <path
                d="M1935 760H2039.5V570H2145"
                stroke="url(#paint23_linear_336_819)"
                strokeWidth={4}
                className="wire"
                style={{ animationDelay: "2.73976s" }}
                />
                <path
                d="M1255 571H1359.5V381H1465"
                stroke="url(#paint24_linear_336_819)"
                strokeWidth={4}
                className="wire"
                style={{ animationDelay: "0.0908382s" }}
                />
                <path
                d="M1425 571H1529.5V381H1635"
                stroke="url(#paint25_linear_336_819)"
                strokeWidth={4}
                className="wire"
                style={{ animationDelay: "0.821612s" }}
                />
                <path
                d="M1595 570H1699.5V380H1805"
                stroke="url(#paint26_linear_336_819)"
                strokeWidth={4}
                className="wire"
                style={{ animationDelay: "1.30628s" }}
                />
                <path
                d="M1765 570H1869.5V380H1975"
                stroke="url(#paint27_linear_336_819)"
                strokeWidth={4}
                className="wire"
                style={{ animationDelay: "2.0025s" }}
                />
                <path
                d="M1935 570H2039.5V380H2145"
                stroke="url(#paint28_linear_336_819)"
                strokeWidth={4}
                className="wire"
                style={{ animationDelay: "2.83464s" }}
                />
                <path
                d="M1255 380H1359.5V190H1465"
                stroke="url(#paint29_linear_336_819)"
                strokeWidth={4}
                className="wire"
                style={{ animationDelay: "1.05622s" }}
                />
                <path
                d="M1425 380H1529.5V190H1635"
                stroke="url(#paint30_linear_336_819)"
                strokeWidth={4}
                className="wire"
                style={{ animationDelay: "2.50671s" }}
                />
                <path
                d="M1595 379H1699.5V189H1805"
                stroke="url(#paint31_linear_336_819)"
                strokeWidth={4}
                className="wire"
                style={{ animationDelay: "0.181215s" }}
                />
                <path
                d="M1425 379H1529.5V189H1635"
                stroke="url(#paint32_linear_336_819)"
                strokeWidth={4}
                className="wire"
                style={{ animationDelay: "2.30396s" }}
                />
                <path
                d="M1595 379H1699.5V189H1805"
                stroke="url(#paint33_linear_336_819)"
                strokeWidth={4}
                className="wire"
                style={{ animationDelay: "2.85787s" }}
                />
                <path
                d="M1765 380H1869.5V190H1975"
                stroke="url(#paint34_linear_336_819)"
                strokeWidth={4}
                className="wire"
                style={{ animationDelay: "1.46668s" }}
                />
                <path
                d="M1935 379H2039.5V189H2145"
                stroke="url(#paint35_linear_336_819)"
                strokeWidth={4}
                className="wire"
                style={{ animationDelay: "1.41107s" }}
                />
                <path
                d="M1255 193H1359.5V3H1465"
                stroke="url(#paint36_linear_336_819)"
                strokeWidth={4}
                className="wire"
                style={{ animationDelay: "0.722444s" }}
                />
                <path
                d="M1255 193H1359.5V3H1465"
                stroke="url(#paint37_linear_336_819)"
                strokeWidth={4}
                className="wire"
                style={{ animationDelay: "1.55117s" }}
                />
                <path
                d="M1765 190H1869.5V0H1975"
                stroke="url(#paint38_linear_336_819)"
                strokeWidth={4}
                className="wire"
                style={{ animationDelay: "1.23476s" }}
                />
                <path
                d="M1935 190H2039.5V0H2145"
                stroke="url(#paint39_linear_336_819)"
                strokeWidth={4}
                className="wire"
                style={{ animationDelay: "1.15532s" }}
                />
                <path
                d="M1595 193H1699.5V3H1805"
                stroke="url(#paint40_linear_336_819)"
                strokeWidth={4}
                className="wire"
                style={{ animationDelay: "1.92815s" }}
                />
                <path
                d="M1425 190H1529.5V0H1635"
                stroke="url(#paint41_linear_336_819)"
                strokeWidth={4}
                className="wire"
                style={{ animationDelay: "2.36184s" }}
                />
                <path
                d="M1425 190H1529.5V0H1635"
                stroke="url(#paint42_linear_336_819)"
                strokeWidth={4}
                className="wire"
                style={{ animationDelay: "0.342795s" }}
                />
                <path
                d="M1085 190H1189.5V0H1295"
                stroke="url(#paint43_linear_336_819)"
                strokeWidth={4}
                className="wire"
                style={{ animationDelay: "2.97611s" }}
                />
                <path
                d="M915 190H1019.5V0H1125"
                stroke="url(#paint44_linear_336_819)"
                strokeWidth={4}
                className="wire"
                style={{ animationDelay: "0.579371s" }}
                />
                <path
                d="M745 189H849.5V-1H955"
                stroke="url(#paint45_linear_336_819)"
                strokeWidth={4}
                className="wire"
                style={{ animationDelay: "2.68431s" }}
                />
                <path
                d="M575 190H679.5V0H785"
                stroke="url(#paint46_linear_336_819)"
                strokeWidth={4}
                className="wire"
                style={{ animationDelay: "0.93206s" }}
                />
                <path
                d="M405 190H509.5V0H615"
                stroke="url(#paint47_linear_336_819)"
                strokeWidth={4}
                className="wire"
                style={{ animationDelay: "2.75582s" }}
                />
                <path
                d="M233 190H337.5V0H443"
                stroke="url(#paint48_linear_336_819)"
                strokeWidth={4}
                className="wire"
                style={{ animationDelay: "2.30674s" }}
                />
                <path
                d="M65 571H169.5V381H275"
                stroke="url(#paint49_linear_336_819)"
                strokeWidth={4}
                className="wire"
                style={{ animationDelay: "0.715562s" }}
                />
                <path
                d="M65 760H169.5V570H275"
                stroke="url(#paint50_linear_336_819)"
                strokeWidth={4}
                className="wire"
                style={{ animationDelay: "0.709452s" }}
                />
                <path
                d="M65 190H169.5V0H275"
                stroke="url(#paint51_linear_336_819)"
                strokeWidth={4}
                className="wire"
                style={{ animationDelay: "2.62402s" }}
                />
                <path
                d="M235 761H339.5V570H445"
                stroke="url(#paint52_linear_336_819)"
                strokeWidth={4}
                className="wire"
                style={{ animationDelay: "1.15326s" }}
                />
                <path
                d="M235 1141H339.5V950H445"
                stroke="url(#paint53_linear_336_819)"
                strokeWidth={4}
                className="wire"
                style={{ animationDelay: "0.429146s" }}
                />
                <path
                d="M405 1142H509.5V951H615"
                stroke="url(#paint54_linear_336_819)"
                strokeWidth={4}
                className="wire"
                style={{ animationDelay: "2.82648s" }}
                />
                <path
                d="M405 570H509.5V379H615"
                stroke="url(#paint55_linear_336_819)"
                strokeWidth={4}
                className="wire"
                style={{ animationDelay: "1.79048s" }}
                />
                <path
                d="M405 761H509.5V570H615"
                stroke="url(#paint56_linear_336_819)"
                strokeWidth={4}
                className="wire"
                style={{ animationDelay: "1.52163s" }}
                />
                <path
                d="M405 380H509.5V189H615"
                stroke="url(#paint57_linear_336_819)"
                strokeWidth={4}
                className="wire"
                style={{ animationDelay: "1.81048s" }}
                />
                <path
                d="M745 381H849.5V190H955"
                stroke="url(#paint58_linear_336_819)"
                strokeWidth={4}
                className="wire"
                style={{ animationDelay: "1.89676s" }}
                />
                <path
                d="M745 950H849.5V759H955"
                stroke="url(#paint59_linear_336_819)"
                strokeWidth={4}
                className="wire"
                style={{ animationDelay: "2.82082s" }}
                />
                <path
                d="M915 950H1019.5V759H1125"
                stroke="url(#paint60_linear_336_819)"
                strokeWidth={4}
                className="wire"
                style={{ animationDelay: "2.57094s" }}
                />
                <path
                d="M1085 761H1189.5V570H1295"
                stroke="url(#paint61_linear_336_819)"
                strokeWidth={4}
                className="wire"
                style={{ animationDelay: "1.26514s" }}
                />
                <path
                d="M915 759H1019.5V568H1125"
                stroke="url(#paint62_linear_336_819)"
                strokeWidth={4}
                className="wire"
                style={{ animationDelay: "0.151804s" }}
                />
                <path
                d="M915 571H1019.5V380H1125"
                stroke="url(#paint63_linear_336_819)"
                strokeWidth={4}
                className="wire"
                style={{ animationDelay: "1.37529s" }}
                />
                <path
                d="M1085 571H1189.5V380H1295"
                stroke="url(#paint64_linear_336_819)"
                strokeWidth={4}
                className="wire"
                style={{ animationDelay: "1.94847s" }}
                />
                <path
                d="M1085 381H1189.5V190H1295"
                stroke="url(#paint65_linear_336_819)"
                strokeWidth={4}
                className="wire"
                style={{ animationDelay: "1.12256s" }}
                />
                <path
                d="M575 950H679.5V759H785"
                stroke="url(#paint66_linear_336_819)"
                strokeWidth={4}
                className="wire"
                style={{ animationDelay: "2.33534s" }}
                />
                <path
                d="M575 572H679.5V381H785"
                stroke="url(#paint67_linear_336_819)"
                strokeWidth={4}
                className="wire"
                style={{ animationDelay: "2.88724s" }}
                />
                <path
                d="M575 763H679.5V572H785"
                stroke="url(#paint68_linear_336_819)"
                strokeWidth={4}
                className="wire"
                style={{ animationDelay: "1.23192s" }}
                />
                <path
                d="M915 380H1019.5V189H1125"
                stroke="url(#paint69_linear_336_819)"
                strokeWidth={4}
                className="wire"
                style={{ animationDelay: "2.67376s" }}
                />
                <path
                d="M66 1140H170.5V949H276"
                stroke="url(#paint70_linear_336_819)"
                strokeWidth={4}
                className="wire"
                style={{ animationDelay: "2.74232s" }}
                />
                <path
                d="M237 951H341.5V760H447"
                stroke="url(#paint71_linear_336_819)"
                strokeWidth={4}
                className="wire"
                style={{ animationDelay: "1.52157s" }}
                />
                <path
                d="M1935 950H2039.5V760H2145"
                stroke="url(#paint72_linear_336_819)"
                strokeWidth={4}
                className="wire"
                style={{ animationDelay: "1.27124s" }}
                />
                <path
                d="M745 570H849.5V380H955"
                stroke="url(#paint73_linear_336_819)"
                strokeWidth={4}
                className="wire"
                style={{ animationDelay: "0.948796s" }}
                />
                <path
                d="M575 380H679.5V190H785"
                stroke="url(#paint74_linear_336_819)"
                strokeWidth={4}
                className="wire"
                style={{ animationDelay: "0.472781s" }}
                />
                <path
                d="M235 380H339.5V190H445"
                stroke="url(#paint75_linear_336_819)"
                strokeWidth={4}
                className="wire"
                style={{ animationDelay: "2.9475s" }}
                />
            </g>
            <defs>
                <linearGradient
                id="paint0_linear_336_819"
                x1="293.971"
                y1="795.526"
                x2="423.741"
                y2="795.054"
                gradientUnits="userSpaceOnUse"
                >
                <stop stopColor="white" stopOpacity={0} />
                <stop offset="0.747917" stopColor="#CFCBFF" />
                <stop offset={1} stopColor="#7673FF" stopOpacity={0} />
                </linearGradient>
                <linearGradient
                id="paint1_linear_336_819"
                x1="109.498"
                y1="1170.62"
                x2="238.448"
                y2="1170.14"
                gradientUnits="userSpaceOnUse"
                >
                <stop stopColor="white" stopOpacity={0} />
                <stop offset="0.747917" stopColor="#CFCBFF" />
                <stop offset={1} stopColor="#7673FF" stopOpacity={0} />
                </linearGradient>
                <linearGradient
                id="paint2_linear_336_819"
                x1="465.37"
                y1="1172.7"
                x2="577.655"
                y2="1172.34"
                gradientUnits="userSpaceOnUse"
                >
                <stop stopColor="white" stopOpacity={0} />
                <stop offset="0.747917" stopColor="#CFCBFF" />
                <stop offset={1} stopColor="#7673FF" stopOpacity={0} />
                </linearGradient>
                <linearGradient
                id="paint3_linear_336_819"
                x1="106.341"
                y1="607.108"
                x2="229.827"
                y2="606.682"
                gradientUnits="userSpaceOnUse"
                >
                <stop stopColor="white" stopOpacity={0} />
                <stop offset="0.747917" stopColor="#CFCBFF" />
                <stop offset={1} stopColor="#7673FF" stopOpacity={0} />
                </linearGradient>
                <linearGradient
                id="paint4_linear_336_819"
                x1="811.29"
                y1="983.362"
                x2="926.034"
                y2="982.991"
                gradientUnits="userSpaceOnUse"
                >
                <stop stopColor="white" stopOpacity={0} />
                <stop offset="0.747917" stopColor="#CFCBFF" />
                <stop offset={1} stopColor="#7673FF" stopOpacity={0} />
                </linearGradient>
                <linearGradient
                id="paint5_linear_336_819"
                x1="641.29"
                y1="1362.2"
                x2="756.034"
                y2="1361.83"
                gradientUnits="userSpaceOnUse"
                >
                <stop stopColor="white" stopOpacity={0} />
                <stop offset="0.747917" stopColor="#CFCBFF" />
                <stop offset={1} stopColor="#7673FF" stopOpacity={0} />
                </linearGradient>
                <linearGradient
                id="paint6_linear_336_819"
                x1="811.29"
                y1="1361.2"
                x2="926.034"
                y2="1360.83"
                gradientUnits="userSpaceOnUse"
                >
                <stop stopColor="white" stopOpacity={0} />
                <stop offset="0.747917" stopColor="#CFCBFF" />
                <stop offset={1} stopColor="#7673FF" stopOpacity={0} />
                </linearGradient>
                <linearGradient
                id="paint7_linear_336_819"
                x1="981.29"
                y1="1361.2"
                x2="1096.03"
                y2="1360.83"
                gradientUnits="userSpaceOnUse"
                >
                <stop stopColor="white" stopOpacity={0} />
                <stop offset="0.747917" stopColor="#CFCBFF" />
                <stop offset={1} stopColor="#7673FF" stopOpacity={0} />
                </linearGradient>
                <linearGradient
                id="paint8_linear_336_819"
                x1="1151.29"
                y1="1361.2"
                x2="1266.03"
                y2="1360.83"
                gradientUnits="userSpaceOnUse"
                >
                <stop stopColor="white" stopOpacity={0} />
                <stop offset="0.747917" stopColor="#CFCBFF" />
                <stop offset={1} stopColor="#7673FF" stopOpacity={0} />
                </linearGradient>
                <linearGradient
                id="paint9_linear_336_819"
                x1="1321.29"
                y1="1361.2"
                x2="1436.03"
                y2="1360.83"
                gradientUnits="userSpaceOnUse"
                >
                <stop stopColor="white" stopOpacity={0} />
                <stop offset="0.747917" stopColor="#CFCBFF" />
                <stop offset={1} stopColor="#7673FF" stopOpacity={0} />
                </linearGradient>
                <linearGradient
                id="paint10_linear_336_819"
                x1="1491.29"
                y1="1361.2"
                x2="1606.03"
                y2="1360.83"
                gradientUnits="userSpaceOnUse"
                >
                <stop stopColor="white" stopOpacity={0} />
                <stop offset="0.747917" stopColor="#CFCBFF" />
                <stop offset={1} stopColor="#7673FF" stopOpacity={0} />
                </linearGradient>
                <linearGradient
                id="paint11_linear_336_819"
                x1="1661.29"
                y1="1361.2"
                x2="1776.03"
                y2="1360.83"
                gradientUnits="userSpaceOnUse"
                >
                <stop stopColor="white" stopOpacity={0} />
                <stop offset="0.747917" stopColor="#CFCBFF" />
                <stop offset={1} stopColor="#7673FF" stopOpacity={0} />
                </linearGradient>
                <linearGradient
                id="paint12_linear_336_819"
                x1="1831.29"
                y1="1361.2"
                x2="1946.03"
                y2="1360.83"
                gradientUnits="userSpaceOnUse"
                >
                <stop stopColor="white" stopOpacity={0} />
                <stop offset="0.747917" stopColor="#CFCBFF" />
                <stop offset={1} stopColor="#7673FF" stopOpacity={0} />
                </linearGradient>
                <linearGradient
                id="paint13_linear_336_819"
                x1="1151.29"
                y1="1172.2"
                x2="1266.03"
                y2="1171.83"
                gradientUnits="userSpaceOnUse"
                >
                <stop stopColor="white" stopOpacity={0} />
                <stop offset="0.747917" stopColor="#CFCBFF" />
                <stop offset={1} stopColor="#7673FF" stopOpacity={0} />
                </linearGradient>
                <linearGradient
                id="paint14_linear_336_819"
                x1="2001.29"
                y1="1361.2"
                x2="2116.03"
                y2="1360.83"
                gradientUnits="userSpaceOnUse"
                >
                <stop stopColor="white" stopOpacity={0} />
                <stop offset="0.747917" stopColor="#CFCBFF" />
                <stop offset={1} stopColor="#7673FF" stopOpacity={0} />
                </linearGradient>
                <linearGradient
                id="paint15_linear_336_819"
                x1="1321.29"
                y1="1171.2"
                x2="1436.03"
                y2="1170.83"
                gradientUnits="userSpaceOnUse"
                >
                <stop stopColor="white" stopOpacity={0} />
                <stop offset="0.747917" stopColor="#CFCBFF" />
                <stop offset={1} stopColor="#7673FF" stopOpacity={0} />
                </linearGradient>
                <linearGradient
                id="paint16_linear_336_819"
                x1="1491.29"
                y1="1171.2"
                x2="1606.03"
                y2="1170.83"
                gradientUnits="userSpaceOnUse"
                >
                <stop stopColor="white" stopOpacity={0} />
                <stop offset="0.747917" stopColor="#CFCBFF" />
                <stop offset={1} stopColor="#7673FF" stopOpacity={0} />
                </linearGradient>
                <linearGradient
                id="paint17_linear_336_819"
                x1="1831.29"
                y1="1171.2"
                x2="1946.03"
                y2="1170.83"
                gradientUnits="userSpaceOnUse"
                >
                <stop stopColor="white" stopOpacity={0} />
                <stop offset="0.747917" stopColor="#CFCBFF" />
                <stop offset={1} stopColor="#7673FF" stopOpacity={0} />
                </linearGradient>
                <linearGradient
                id="paint18_linear_336_819"
                x1="1661.29"
                y1="1171.2"
                x2="1776.03"
                y2="1170.83"
                gradientUnits="userSpaceOnUse"
                >
                <stop stopColor="white" stopOpacity={0} />
                <stop offset="0.747917" stopColor="#CFCBFF" />
                <stop offset={1} stopColor="#7673FF" stopOpacity={0} />
                </linearGradient>
                <linearGradient
                id="paint19_linear_336_819"
                x1="1321.29"
                y1="982.198"
                x2="1436.03"
                y2="981.825"
                gradientUnits="userSpaceOnUse"
                >
                <stop stopColor="white" stopOpacity={0} />
                <stop offset="0.747917" stopColor="#CFCBFF" />
                <stop offset={1} stopColor="#7673FF" stopOpacity={0} />
                </linearGradient>
                <linearGradient
                id="paint20_linear_336_819"
                x1="1491.29"
                y1="982.198"
                x2="1606.03"
                y2="981.825"
                gradientUnits="userSpaceOnUse"
                >
                <stop stopColor="white" stopOpacity={0} />
                <stop offset="0.747917" stopColor="#CFCBFF" />
                <stop offset={1} stopColor="#7673FF" stopOpacity={0} />
                </linearGradient>
                <linearGradient
                id="paint21_linear_336_819"
                x1="1661.29"
                y1="981.198"
                x2="1776.03"
                y2="980.825"
                gradientUnits="userSpaceOnUse"
                >
                <stop stopColor="white" stopOpacity={0} />
                <stop offset="0.747917" stopColor="#CFCBFF" />
                <stop offset={1} stopColor="#7673FF" stopOpacity={0} />
                </linearGradient>
                <linearGradient
                id="paint22_linear_336_819"
                x1="1831.29"
                y1="981.198"
                x2="1946.03"
                y2="980.825"
                gradientUnits="userSpaceOnUse"
                >
                <stop stopColor="white" stopOpacity={0} />
                <stop offset="0.747917" stopColor="#CFCBFF" />
                <stop offset={1} stopColor="#7673FF" stopOpacity={0} />
                </linearGradient>
                <linearGradient
                id="paint23_linear_336_819"
                x1="2001.29"
                y1="981.198"
                x2="2116.03"
                y2="980.825"
                gradientUnits="userSpaceOnUse"
                >
                <stop stopColor="white" stopOpacity={0} />
                <stop offset="0.747917" stopColor="#CFCBFF" />
                <stop offset={1} stopColor="#7673FF" stopOpacity={0} />
                </linearGradient>
                <linearGradient
                id="paint24_linear_336_819"
                x1="1321.29"
                y1="792.198"
                x2="1436.03"
                y2="791.825"
                gradientUnits="userSpaceOnUse"
                >
                <stop stopColor="white" stopOpacity={0} />
                <stop offset="0.747917" stopColor="#CFCBFF" />
                <stop offset={1} stopColor="#7673FF" stopOpacity={0} />
                </linearGradient>
                <linearGradient
                id="paint25_linear_336_819"
                x1="1491.29"
                y1="792.198"
                x2="1606.03"
                y2="791.825"
                gradientUnits="userSpaceOnUse"
                >
                <stop stopColor="white" stopOpacity={0} />
                <stop offset="0.747917" stopColor="#CFCBFF" />
                <stop offset={1} stopColor="#7673FF" stopOpacity={0} />
                </linearGradient>
                <linearGradient
                id="paint26_linear_336_819"
                x1="1661.29"
                y1="791.198"
                x2="1776.03"
                y2="790.825"
                gradientUnits="userSpaceOnUse"
                >
                <stop stopColor="white" stopOpacity={0} />
                <stop offset="0.747917" stopColor="#CFCBFF" />
                <stop offset={1} stopColor="#7673FF" stopOpacity={0} />
                </linearGradient>
                <linearGradient
                id="paint27_linear_336_819"
                x1="1831.29"
                y1="791.198"
                x2="1946.03"
                y2="790.825"
                gradientUnits="userSpaceOnUse"
                >
                <stop stopColor="white" stopOpacity={0} />
                <stop offset="0.747917" stopColor="#CFCBFF" />
                <stop offset={1} stopColor="#7673FF" stopOpacity={0} />
                </linearGradient>
                <linearGradient
                id="paint28_linear_336_819"
                x1="2001.29"
                y1="791.198"
                x2="2116.03"
                y2="790.825"
                gradientUnits="userSpaceOnUse"
                >
                <stop stopColor="white" stopOpacity={0} />
                <stop offset="0.747917" stopColor="#CFCBFF" />
                <stop offset={1} stopColor="#7673FF" stopOpacity={0} />
                </linearGradient>
                <linearGradient
                id="paint29_linear_336_819"
                x1="1321.29"
                y1="601.198"
                x2="1436.03"
                y2="600.825"
                gradientUnits="userSpaceOnUse"
                >
                <stop stopColor="white" stopOpacity={0} />
                <stop offset="0.747917" stopColor="#CFCBFF" />
                <stop offset={1} stopColor="#7673FF" stopOpacity={0} />
                </linearGradient>
                <linearGradient
                id="paint30_linear_336_819"
                x1="1491.29"
                y1="601.198"
                x2="1606.03"
                y2="600.825"
                gradientUnits="userSpaceOnUse"
                >
                <stop stopColor="white" stopOpacity={0} />
                <stop offset="0.747917" stopColor="#CFCBFF" />
                <stop offset={1} stopColor="#7673FF" stopOpacity={0} />
                </linearGradient>
                <linearGradient
                id="paint31_linear_336_819"
                x1="1661.29"
                y1="600.198"
                x2="1776.03"
                y2="599.825"
                gradientUnits="userSpaceOnUse"
                >
                <stop stopColor="white" stopOpacity={0} />
                <stop offset="0.747917" stopColor="#CFCBFF" />
                <stop offset={1} stopColor="#7673FF" stopOpacity={0} />
                </linearGradient>
                <linearGradient
                id="paint32_linear_336_819"
                x1="1491.29"
                y1="600.198"
                x2="1606.03"
                y2="599.825"
                gradientUnits="userSpaceOnUse"
                >
                <stop stopColor="white" stopOpacity={0} />
                <stop offset="0.747917" stopColor="#CFCBFF" />
                <stop offset={1} stopColor="#7673FF" stopOpacity={0} />
                </linearGradient>
                <linearGradient
                id="paint33_linear_336_819"
                x1="1661.29"
                y1="600.198"
                x2="1776.03"
                y2="599.825"
                gradientUnits="userSpaceOnUse"
                >
                <stop stopColor="white" stopOpacity={0} />
                <stop offset="0.747917" stopColor="#CFCBFF" />
                <stop offset={1} stopColor="#7673FF" stopOpacity={0} />
                </linearGradient>
                <linearGradient
                id="paint34_linear_336_819"
                x1="1831.29"
                y1="601.198"
                x2="1946.03"
                y2="600.825"
                gradientUnits="userSpaceOnUse"
                >
                <stop stopColor="white" stopOpacity={0} />
                <stop offset="0.747917" stopColor="#CFCBFF" />
                <stop offset={1} stopColor="#7673FF" stopOpacity={0} />
                </linearGradient>
                <linearGradient
                id="paint35_linear_336_819"
                x1="2001.29"
                y1="600.198"
                x2="2116.03"
                y2="599.825"
                gradientUnits="userSpaceOnUse"
                >
                <stop stopColor="white" stopOpacity={0} />
                <stop offset="0.747917" stopColor="#CFCBFF" />
                <stop offset={1} stopColor="#7673FF" stopOpacity={0} />
                </linearGradient>
                <linearGradient
                id="paint36_linear_336_819"
                x1="1321.29"
                y1="414.198"
                x2="1436.03"
                y2="413.825"
                gradientUnits="userSpaceOnUse"
                >
                <stop stopColor="white" stopOpacity={0} />
                <stop offset="0.747917" stopColor="#CFCBFF" />
                <stop offset={1} stopColor="#7673FF" stopOpacity={0} />
                </linearGradient>
                <linearGradient
                id="paint37_linear_336_819"
                x1="1321.29"
                y1="414.198"
                x2="1436.03"
                y2="413.825"
                gradientUnits="userSpaceOnUse"
                >
                <stop stopColor="white" stopOpacity={0} />
                <stop offset="0.747917" stopColor="#CFCBFF" />
                <stop offset={1} stopColor="#7673FF" stopOpacity={0} />
                </linearGradient>
                <linearGradient
                id="paint38_linear_336_819"
                x1="1831.29"
                y1="411.198"
                x2="1946.03"
                y2="410.825"
                gradientUnits="userSpaceOnUse"
                >
                <stop stopColor="white" stopOpacity={0} />
                <stop offset="0.747917" stopColor="#CFCBFF" />
                <stop offset={1} stopColor="#7673FF" stopOpacity={0} />
                </linearGradient>
                <linearGradient
                id="paint39_linear_336_819"
                x1="2001.29"
                y1="411.198"
                x2="2116.03"
                y2="410.825"
                gradientUnits="userSpaceOnUse"
                >
                <stop stopColor="white" stopOpacity={0} />
                <stop offset="0.747917" stopColor="#CFCBFF" />
                <stop offset={1} stopColor="#7673FF" stopOpacity={0} />
                </linearGradient>
                <linearGradient
                id="paint40_linear_336_819"
                x1="1661.29"
                y1="414.198"
                x2="1776.03"
                y2="413.825"
                gradientUnits="userSpaceOnUse"
                >
                <stop stopColor="white" stopOpacity={0} />
                <stop offset="0.747917" stopColor="#CFCBFF" />
                <stop offset={1} stopColor="#7673FF" stopOpacity={0} />
                </linearGradient>
                <linearGradient
                id="paint41_linear_336_819"
                x1="1491.29"
                y1="411.198"
                x2="1606.03"
                y2="410.825"
                gradientUnits="userSpaceOnUse"
                >
                <stop stopColor="white" stopOpacity={0} />
                <stop offset="0.747917" stopColor="#CFCBFF" />
                <stop offset={1} stopColor="#7673FF" stopOpacity={0} />
                </linearGradient>
                <linearGradient
                id="paint42_linear_336_819"
                x1="1491.29"
                y1="411.198"
                x2="1606.03"
                y2="410.825"
                gradientUnits="userSpaceOnUse"
                >
                <stop stopColor="white" stopOpacity={0} />
                <stop offset="0.747917" stopColor="#CFCBFF" />
                <stop offset={1} stopColor="#7673FF" stopOpacity={0} />
                </linearGradient>
                <linearGradient
                id="paint43_linear_336_819"
                x1="1151.29"
                y1="411.198"
                x2="1266.03"
                y2="410.825"
                gradientUnits="userSpaceOnUse"
                >
                <stop stopColor="white" stopOpacity={0} />
                <stop offset="0.747917" stopColor="#CFCBFF" />
                <stop offset={1} stopColor="#7673FF" stopOpacity={0} />
                </linearGradient>
                <linearGradient
                id="paint44_linear_336_819"
                x1="981.29"
                y1="411.198"
                x2="1096.03"
                y2="410.825"
                gradientUnits="userSpaceOnUse"
                >
                <stop stopColor="white" stopOpacity={0} />
                <stop offset="0.747917" stopColor="#CFCBFF" />
                <stop offset={1} stopColor="#7673FF" stopOpacity={0} />
                </linearGradient>
                <linearGradient
                id="paint45_linear_336_819"
                x1="811.29"
                y1="410.198"
                x2="926.034"
                y2="409.825"
                gradientUnits="userSpaceOnUse"
                >
                <stop stopColor="white" stopOpacity={0} />
                <stop offset="0.747917" stopColor="#CFCBFF" />
                <stop offset={1} stopColor="#7673FF" stopOpacity={0} />
                </linearGradient>
                <linearGradient
                id="paint46_linear_336_819"
                x1="641.29"
                y1="411.198"
                x2="756.034"
                y2="410.825"
                gradientUnits="userSpaceOnUse"
                >
                <stop stopColor="white" stopOpacity={0} />
                <stop offset="0.747917" stopColor="#CFCBFF" />
                <stop offset={1} stopColor="#7673FF" stopOpacity={0} />
                </linearGradient>
                <linearGradient
                id="paint47_linear_336_819"
                x1="471.29"
                y1="411.198"
                x2="586.034"
                y2="410.825"
                gradientUnits="userSpaceOnUse"
                >
                <stop stopColor="white" stopOpacity={0} />
                <stop offset="0.747917" stopColor="#CFCBFF" />
                <stop offset={1} stopColor="#7673FF" stopOpacity={0} />
                </linearGradient>
                <linearGradient
                id="paint48_linear_336_819"
                x1="299.29"
                y1="411.198"
                x2="414.034"
                y2="410.825"
                gradientUnits="userSpaceOnUse"
                >
                <stop stopColor="white" stopOpacity={0} />
                <stop offset="0.747917" stopColor="#CFCBFF" />
                <stop offset={1} stopColor="#7673FF" stopOpacity={0} />
                </linearGradient>
                <linearGradient
                id="paint49_linear_336_819"
                x1="131.29"
                y1="792.198"
                x2="246.034"
                y2="791.825"
                gradientUnits="userSpaceOnUse"
                >
                <stop stopColor="white" stopOpacity={0} />
                <stop offset="0.747917" stopColor="#CFCBFF" />
                <stop offset={1} stopColor="#7673FF" stopOpacity={0} />
                </linearGradient>
                <linearGradient
                id="paint50_linear_336_819"
                x1="131.29"
                y1="981.198"
                x2="246.034"
                y2="980.825"
                gradientUnits="userSpaceOnUse"
                >
                <stop stopColor="white" stopOpacity={0} />
                <stop offset="0.747917" stopColor="#CFCBFF" />
                <stop offset={1} stopColor="#7673FF" stopOpacity={0} />
                </linearGradient>
                <linearGradient
                id="paint51_linear_336_819"
                x1="131.29"
                y1="411.198"
                x2="246.034"
                y2="410.825"
                gradientUnits="userSpaceOnUse"
                >
                <stop stopColor="white" stopOpacity={0} />
                <stop offset="0.747917" stopColor="#CFCBFF" />
                <stop offset={1} stopColor="#7673FF" stopOpacity={0} />
                </linearGradient>
                <linearGradient
                id="paint52_linear_336_819"
                x1="301.29"
                y1="983.362"
                x2="416.034"
                y2="982.991"
                gradientUnits="userSpaceOnUse"
                >
                <stop stopColor="white" stopOpacity={0} />
                <stop offset="0.747917" stopColor="#CFCBFF" />
                <stop offset={1} stopColor="#7673FF" stopOpacity={0} />
                </linearGradient>
                <linearGradient
                id="paint53_linear_336_819"
                x1="301.29"
                y1="1363.36"
                x2="416.034"
                y2="1362.99"
                gradientUnits="userSpaceOnUse"
                >
                <stop stopColor="white" stopOpacity={0} />
                <stop offset="0.747917" stopColor="#CFCBFF" />
                <stop offset={1} stopColor="#7673FF" stopOpacity={0} />
                </linearGradient>
                <linearGradient
                id="paint54_linear_336_819"
                x1="471.29"
                y1="1364.36"
                x2="586.034"
                y2="1363.99"
                gradientUnits="userSpaceOnUse"
                >
                <stop stopColor="white" stopOpacity={0} />
                <stop offset="0.747917" stopColor="#CFCBFF" />
                <stop offset={1} stopColor="#7673FF" stopOpacity={0} />
                </linearGradient>
                <linearGradient
                id="paint55_linear_336_819"
                x1="471.29"
                y1="792.362"
                x2="586.034"
                y2="791.991"
                gradientUnits="userSpaceOnUse"
                >
                <stop stopColor="white" stopOpacity={0} />
                <stop offset="0.747917" stopColor="#CFCBFF" />
                <stop offset={1} stopColor="#7673FF" stopOpacity={0} />
                </linearGradient>
                <linearGradient
                id="paint56_linear_336_819"
                x1="471.29"
                y1="983.362"
                x2="586.034"
                y2="982.991"
                gradientUnits="userSpaceOnUse"
                >
                <stop stopColor="white" stopOpacity={0} />
                <stop offset="0.747917" stopColor="#CFCBFF" />
                <stop offset={1} stopColor="#7673FF" stopOpacity={0} />
                </linearGradient>
                <linearGradient
                id="paint57_linear_336_819"
                x1="471.29"
                y1="602.362"
                x2="586.034"
                y2="601.991"
                gradientUnits="userSpaceOnUse"
                >
                <stop stopColor="white" stopOpacity={0} />
                <stop offset="0.747917" stopColor="#CFCBFF" />
                <stop offset={1} stopColor="#7673FF" stopOpacity={0} />
                </linearGradient>
                <linearGradient
                id="paint58_linear_336_819"
                x1="811.29"
                y1="603.362"
                x2="926.034"
                y2="602.991"
                gradientUnits="userSpaceOnUse"
                >
                <stop stopColor="white" stopOpacity={0} />
                <stop offset="0.747917" stopColor="#CFCBFF" />
                <stop offset={1} stopColor="#7673FF" stopOpacity={0} />
                </linearGradient>
                <linearGradient
                id="paint59_linear_336_819"
                x1="811.29"
                y1="1172.36"
                x2="926.034"
                y2="1171.99"
                gradientUnits="userSpaceOnUse"
                >
                <stop stopColor="white" stopOpacity={0} />
                <stop offset="0.747917" stopColor="#CFCBFF" />
                <stop offset={1} stopColor="#7673FF" stopOpacity={0} />
                </linearGradient>
                <linearGradient
                id="paint60_linear_336_819"
                x1="981.29"
                y1="1172.36"
                x2="1096.03"
                y2="1171.99"
                gradientUnits="userSpaceOnUse"
                >
                <stop stopColor="white" stopOpacity={0} />
                <stop offset="0.747917" stopColor="#CFCBFF" />
                <stop offset={1} stopColor="#7673FF" stopOpacity={0} />
                </linearGradient>
                <linearGradient
                id="paint61_linear_336_819"
                x1="1151.29"
                y1="983.362"
                x2="1266.03"
                y2="982.991"
                gradientUnits="userSpaceOnUse"
                >
                <stop stopColor="white" stopOpacity={0} />
                <stop offset="0.747917" stopColor="#CFCBFF" />
                <stop offset={1} stopColor="#7673FF" stopOpacity={0} />
                </linearGradient>
                <linearGradient
                id="paint62_linear_336_819"
                x1="981.29"
                y1="981.362"
                x2="1096.03"
                y2="980.991"
                gradientUnits="userSpaceOnUse"
                >
                <stop stopColor="white" stopOpacity={0} />
                <stop offset="0.747917" stopColor="#CFCBFF" />
                <stop offset={1} stopColor="#7673FF" stopOpacity={0} />
                </linearGradient>
                <linearGradient
                id="paint63_linear_336_819"
                x1="981.29"
                y1="793.362"
                x2="1096.03"
                y2="792.991"
                gradientUnits="userSpaceOnUse"
                >
                <stop stopColor="white" stopOpacity={0} />
                <stop offset="0.747917" stopColor="#CFCBFF" />
                <stop offset={1} stopColor="#7673FF" stopOpacity={0} />
                </linearGradient>
                <linearGradient
                id="paint64_linear_336_819"
                x1="1151.29"
                y1="793.362"
                x2="1266.03"
                y2="792.991"
                gradientUnits="userSpaceOnUse"
                >
                <stop stopColor="white" stopOpacity={0} />
                <stop offset="0.747917" stopColor="#CFCBFF" />
                <stop offset={1} stopColor="#7673FF" stopOpacity={0} />
                </linearGradient>
                <linearGradient
                id="paint65_linear_336_819"
                x1="1151.29"
                y1="603.362"
                x2="1266.03"
                y2="602.991"
                gradientUnits="userSpaceOnUse"
                >
                <stop stopColor="white" stopOpacity={0} />
                <stop offset="0.747917" stopColor="#CFCBFF" />
                <stop offset={1} stopColor="#7673FF" stopOpacity={0} />
                </linearGradient>
                <linearGradient
                id="paint66_linear_336_819"
                x1="641.29"
                y1="1172.36"
                x2="756.034"
                y2="1171.99"
                gradientUnits="userSpaceOnUse"
                >
                <stop stopColor="white" stopOpacity={0} />
                <stop offset="0.747917" stopColor="#CFCBFF" />
                <stop offset={1} stopColor="#7673FF" stopOpacity={0} />
                </linearGradient>
                <linearGradient
                id="paint67_linear_336_819"
                x1="641.29"
                y1="794.362"
                x2="756.034"
                y2="793.991"
                gradientUnits="userSpaceOnUse"
                >
                <stop stopColor="white" stopOpacity={0} />
                <stop offset="0.747917" stopColor="#CFCBFF" />
                <stop offset={1} stopColor="#7673FF" stopOpacity={0} />
                </linearGradient>
                <linearGradient
                id="paint68_linear_336_819"
                x1="641.29"
                y1="985.362"
                x2="756.034"
                y2="984.991"
                gradientUnits="userSpaceOnUse"
                >
                <stop stopColor="white" stopOpacity={0} />
                <stop offset="0.747917" stopColor="#CFCBFF" />
                <stop offset={1} stopColor="#7673FF" stopOpacity={0} />
                </linearGradient>
                <linearGradient
                id="paint69_linear_336_819"
                x1="981.29"
                y1="602.362"
                x2="1096.03"
                y2="601.991"
                gradientUnits="userSpaceOnUse"
                >
                <stop stopColor="white" stopOpacity={0} />
                <stop offset="0.747917" stopColor="#CFCBFF" />
                <stop offset={1} stopColor="#7673FF" stopOpacity={0} />
                </linearGradient>
                <linearGradient
                id="paint70_linear_336_819"
                x1="132.29"
                y1="1362.36"
                x2="247.034"
                y2="1361.99"
                gradientUnits="userSpaceOnUse"
                >
                <stop stopColor="white" stopOpacity={0} />
                <stop offset="0.747917" stopColor="#CFCBFF" />
                <stop offset={1} stopColor="#7673FF" stopOpacity={0} />
                </linearGradient>
                <linearGradient
                id="paint71_linear_336_819"
                x1="303.29"
                y1="1173.36"
                x2="418.034"
                y2="1172.99"
                gradientUnits="userSpaceOnUse"
                >
                <stop stopColor="white" stopOpacity={0} />
                <stop offset="0.747917" stopColor="#CFCBFF" />
                <stop offset={1} stopColor="#7673FF" stopOpacity={0} />
                </linearGradient>
                <linearGradient
                id="paint72_linear_336_819"
                x1="2001.29"
                y1="1171.2"
                x2="2116.03"
                y2="1170.83"
                gradientUnits="userSpaceOnUse"
                >
                <stop stopColor="white" stopOpacity={0} />
                <stop offset="0.747917" stopColor="#CFCBFF" />
                <stop offset={1} stopColor="#7673FF" stopOpacity={0} />
                </linearGradient>
                <linearGradient
                id="paint73_linear_336_819"
                x1="811.29"
                y1="791.198"
                x2="926.034"
                y2="790.825"
                gradientUnits="userSpaceOnUse"
                >
                <stop stopColor="white" stopOpacity={0} />
                <stop offset="0.747917" stopColor="#CFCBFF" />
                <stop offset={1} stopColor="#7673FF" stopOpacity={0} />
                </linearGradient>
                <linearGradient
                id="paint74_linear_336_819"
                x1="641.29"
                y1="601.198"
                x2="756.034"
                y2="600.825"
                gradientUnits="userSpaceOnUse"
                >
                <stop stopColor="white" stopOpacity={0} />
                <stop offset="0.747917" stopColor="#CFCBFF" />
                <stop offset={1} stopColor="#7673FF" stopOpacity={0} />
                </linearGradient>
                <linearGradient
                id="paint75_linear_336_819"
                x1="301.29"
                y1="601.198"
                x2="416.034"
                y2="600.825"
                gradientUnits="userSpaceOnUse"
                >
                <stop stopColor="white" stopOpacity={0} />
                <stop offset="0.747917" stopColor="#CFCBFF" />
                <stop offset={1} stopColor="#7673FF" stopOpacity={0} />
                </linearGradient>
                <clipPath id="clip0_336_819">
                <rect width={2210} height={1140} fill="white" />
                </clipPath>
                <clipPath id="clip1_336_819">
                <rect width={2210} height={1140} fill="white" />
                </clipPath>
                <clipPath id="clip2_336_819">
                <rect width={2210} height={190} fill="white" />
                </clipPath>
                <clipPath id="clip3_336_819">
                <rect
                    width={2210}
                    height={190}
                    fill="white"
                    transform="translate(0 190)"
                />
                </clipPath>
                <clipPath id="clip4_336_819">
                <rect
                    width={2210}
                    height={190}
                    fill="white"
                    transform="translate(0 380)"
                />
                </clipPath>
                <clipPath id="clip5_336_819">
                <rect
                    width={2210}
                    height={190}
                    fill="white"
                    transform="translate(0 570)"
                />
                </clipPath>
                <clipPath id="clip6_336_819">
                <rect
                    width={2210}
                    height={190}
                    fill="white"
                    transform="translate(0 760)"
                />
                </clipPath>
                <clipPath id="clip7_336_819">
                <rect
                    width={2210}
                    height={190}
                    fill="white"
                    transform="translate(0 950)"
                />
                </clipPath>
            </defs>
        </svg>
  </div>
}

export default HeroPolygon